* {
	box-sizing: border-box;
}

body {
	font-weight: 400;
	line-height: 1.625em;
	width: 100%;
	height: 100%;
	margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,h2,h3,h4,h5,h6 {
	font-weight: 300;
}

code, pre {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  margin: 0;
}
.container {
  max-width: 768px;
  width: 100%;
  margin: 0 auto;
  padding: 0 1em;
}