.scoreboard {
    display: flex;
    margin-bottom: 1em;
}
.scoreboard .player1,
.scoreboard .player2 {
    width: 50%;
    display:flex;
}
.scoreboard .player2 {
    text-align: right;
}
