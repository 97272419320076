h1 > span {
  font-size: 0.5em;
  line-height: 1;
}

h1, h2, h3 {
  margin-top: 2rem;
}

h3 > .iconLink {
  margin-right: 1em;
}

.App-header {
  height: 141px;
  height: calc(125px + 1em);
  padding-top: 1px;
}

.App-header img {
  float: left;
  width: 125px;
  border-radius: 100%;
  margin: 1em 1em 0 0;
}

.App-header h1 {
  margin: 1em 0 1.5em 0;
  padding: 0.5em 0 0 0;
}

.onlineLinks {
  font-size: 2em;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.onlineLinks li {
  display: inline-block;
  margin-right: 1rem;
}

