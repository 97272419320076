
.iconLink {
  text-decoration: none;
  color: inherit;
}

.iconLink svg {
  display: inline-block;
  position: relative;
  top: 0.125em;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}