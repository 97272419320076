.ticTacToe .scoreboard {
    text-align: center;
    width: 5em;
    margin: 0 auto 1em;
}
.ticTacToe .scoreCard {
    padding: 0;
}

.ticTacToeBoard {
    font-size: 24px;
    width: 9em;
    margin: 0 auto;
}
.ticTacToeBoard tr {
    display: flex; 
    width: 100%;
    border-top: 2px solid black
}
.ticTacToeBoard tr:first-of-type {
    border-top: 0
}
.ticTacToeBoard td {
    width: 3em;
    height: 2em;
    border-left: 2px solid black;
    text-align: center;
    padding: 0.325em;
}
.ticTacToeBoard td:first-of-type {
    border-left: none;
}

.ticTacToeBoard .col-0-win td:first-of-type,
.ticTacToeBoard .col-1-win td:nth-of-type(2),
.ticTacToeBoard .col-2-win td:nth-of-type(3) {
    background: linear-gradient(to left, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 48%,rgba(0,0,0,1) 50%,rgba(0,0,0,0) 52%,rgba(0,0,0,0) 100%);
}

.ticTacToeBoard .row-0-win tr:first-of-type,
.ticTacToeBoard .row-1-win tr:nth-of-type(2),
.ticTacToeBoard .row-2-win tr:nth-of-type(3) {
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 48%,rgba(0,0,0,1) 50%,rgba(0,0,0,0) 52%,rgba(0,0,0,0) 100%);
}

.ticTacToeBoard .diag-left-win tr:first-of-type td:first-of-type,
.ticTacToeBoard .diag-left-win tr:nth-of-type(2) td:nth-of-type(2),
.ticTacToeBoard .diag-left-win tr:nth-of-type(3) td:nth-of-type(3) {
    background: linear-gradient(35deg, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 48%,rgba(0,0,0,1) 50%,rgba(0,0,0,0) 52%,rgba(0,0,0,0) 100%);
}

.ticTacToeBoard .diag-right-win tr:first-of-type td:nth-of-type(3),
.ticTacToeBoard .diag-right-win tr:nth-of-type(2) td:nth-of-type(2),
.ticTacToeBoard .diag-right-win tr:nth-of-type(3) td:first-of-type {
    background: linear-gradient(-35deg, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 48%,rgba(0,0,0,1) 50%,rgba(0,0,0,0) 52%,rgba(0,0,0,0) 100%);
}

