svg.docIcon {
    display: inline-block;
    position: relative;
    top: 0.1875em;
    width: 1em;
    height: 1em;
    margin-right: 0.25em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
  }