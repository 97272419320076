.connect4board .row {
    background-color: gray;
    display: flex;
    width: 100%;
    margin: 0 auto;
}

.connect4board .slot {
    box-sizing: border-box;
    border: 2px solid gray;
    border-radius: 100%;
    border-width: 2px;
    background-color: white;
    overflow: hidden;
    width: calc(1/7 * 100%);
    aspect-ratio: 1;
}

.connect4board .slot.winner {
    box-shadow: 0 0 4px 4px rgba(255,255,255,0.8);
    border-color: white;
    z-index: 2;
}

.connect4board [class$=token] {
    background-color: #fa8acf;
    aspect-ratio: 1;
    width: 100%;
}
.connect4board .blue-token {
    background-color: #60d8e6;
}

.connect4board .player1 {
    background: #fa8acf;
    border: 2px solid #fc54bb;
    border-right: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.connect4board .player2 {
    background: #60d8e6;
    border: 2px solid #26bdce;
    border-left: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.connect4board .scoreCard .turnActions {
    text-shadow: 1px 1px 0px white;
}
.connect4board .scoreCard .score {
    text-shadow: 1px 1px 0px white;
    font-size: 2em;
}